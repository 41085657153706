import axios from "axios";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Row,
} from "reactstrap";

import { Environment } from "../../../../core/env/environment";
import { LocalStorageService } from "../../../../core/storage-service/LocalStorageService";
import LoginOrRegister from "../../../login-or-register/LoginOrRegister";
import GenericNotFound from "../../../misc-components/GenericNotFound";
import LinkToSelectedPackage from "../../link/LinkToSelectedPackage";
import DiagonalPackageMarks from "./DiagonalPackageMarks";
import { PackageService } from "./PackageService";

import "../../../../App.css";

const PackagesAndPricesTeaser = (props) => {
  const ENTITY = "packages";
  const [packages, setPackages] = useState([]);
  const [packageTitles, setPackageTitles] = useState([]);
  const [yearlySubscription, setYearlySubscrition] = useState(false);


  useEffect(() => {
    if (packages.length) {
      return;
    }
    function addPackageTitle(title) {
      let titles = packageTitles;
      titles.push(title);
      setPackageTitles(titles);
    }

    const stored = LocalStorageService.find(ENTITY);

    if (stored.length) {
      setPackages(stored);
      stored.forEach((p) => {
        addPackageTitle(p.attributes.title);
      });
      return;
    }
    axios
      .get(
        Environment.getEntityTypeApiEndpoint("packages?populate=*"),
        Environment.getApiRequestHeaders()
      )
      .then(({ data }) => {
        data.data = data.data.sort((a, b) =>
          a.attributes.weight < b.attributes.weight ? -1 : 1
        );
        data.data.forEach((p) => {
          addPackageTitle(p.attributes.title);
        });
        setPackages(data.data);
        LocalStorageService.store(ENTITY, data.data);
      })
      .catch((error) => {
        // ignored
      });
  }, [packages, packageTitles]);

  if (!packages || packages?.length === 0) {
    return GenericNotFound({
      error: "",
      content: packages?.length > 0 ? true : false,
      invalidPath: false,
      translator: props.translator
    });
  }

  return (
    <div style={{ minHeight: "85vh" }}>
      <Row className="mt-5 pt-5">
        <Col lg="12" md="12" sm="12" xs="12" className="align-middle">
          <h2 className="text-center main-content-header-color">
            {props.translator.t('ELOFIZETOI_CSOMAGOK')}<br></br>
            <small className="text-secondary">
              {" "}
              {props.translator.t('ELOFIZETOI_CSOMAGOK_DESCRIPTION')}
            </small>
          </h2>
        </Col>
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          className="d-flex justify-content-center mt-3"
        >
          <div className="d-flex">
            <div className="m-2">
              <Button
                className={
                  !yearlySubscription
                    ? "main-content-bg-color"
                    : "bg-light color-ec-3"
                }
                onClick={() => setYearlySubscrition(false)}
              >
                {props.translator.t('HAVI_ELOFIZETES')}{" "}
              </Button>
            </div>
            <div className="m-2">
              <Button
                className={
                  yearlySubscription
                    ? "main-content-bg-color"
                    : "bg-light color-ec-3"
                }
                onClick={() => setYearlySubscrition(true)}
              >
               {props.translator.t('EVES_ELOFIZETES')}{" "}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-around mt-5 pt-3">
        {(() => {
          let container = [];
          packages.forEach((item, index) => {
            container.push(
              <Col lg="2" md="3" sm="6" xs="12" key={index} className="rounded p-2 mb-5 package-wrapper bg-light">
                <ListGroupItem className="package-border" style={{ height: "22rem" }}>
                  <ListGroupItemHeading
                    className={
                      "p-3 m-3 rounded text-center text-light custom card-bg-" +
                      PackageService.findCardBgClassName(item.attributes.title)
                    } style={{ height: "5rem" }}>
                    {props.translator.t(item.attributes.title?.replace(" ", "_"))}
                  </ListGroupItemHeading>
                  <ListGroupItemText
                    style={{ height: "4rem" }}
                    className="m-3 p-1 text-center">
                    {item.attributes.description}
                  </ListGroupItemText>
                  <div className="text-center p-2">
                    {PackageService.createPriceContainer(
                      index,
                      item,
                      yearlySubscription,
                      PackageService.findCardBgClassName(item.attributes.title)
                    )}
                  </div>
                  <DiagonalPackageMarks
                    index={index}
                    key={item.attributes.id}
                    packageTitles={packageTitles}
                    findCardBgClassName={PackageService.findCardBgClassName}
                  ></DiagonalPackageMarks>
                  <LinkToSelectedPackage
                    findCardBgClassName={PackageService.findCardBgClassName}
                    package={item}
                    translator={props.translator}
                  ></LinkToSelectedPackage>
                </ListGroupItem>
              </Col>
            );
          });
          return container;
        })()}
      </Row>
      <Row className="justify-content-around mt-5 pt-3">
        <Col lg="8" md="8" sm="10" xs="10" className="mt-5">
          <LoginOrRegister translator={props.translator} ></LoginOrRegister>
        </Col>
      </Row>
    </div>
  );
};

export default PackagesAndPricesTeaser;
