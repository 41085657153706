import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button } from "reactstrap";
import './StickyButton.css';

const ScrollToTopButton = () => {
  
  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };

  function scrollToTop() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  window.addEventListener('scroll', toggleVisible);

  return (
    <div style={{display: visible ? 'inline' : 'none'}}>
      <Button className="position-fixed zindex-fixed bottom-0 end-0 bg-light text-success sticky-button" onClick={scrollToTop}>
        <FontAwesomeIcon icon={solid("arrow-up")} size="1x" />
        <FontAwesomeIcon icon={solid("arrow-up")} size="2x" />
      </Button>
    </div>
    
  );
};

export default ScrollToTopButton;
