import './DiagonalPackageMarks.css';

const DiagonalPackageMarks = (props) => {
  
  return (
    <div className="d-flex justify-content-center diagonal-wrapper mt-2">
      {(() => {
        let subContainer = [];
        for (let i = 0; i < props.index + 1; i++) {
          subContainer.push(
            <div key={i}
              className={
                "diagonal diagonal-bg-" +
                props.findCardBgClassName(props.packageTitles[i])
              }
            ></div>
          );
        }
        return subContainer;
      })()}
    </div>
  );
};

export default DiagonalPackageMarks;
