import { Helmet } from "react-helmet";
import { Environment } from "../../core/env/environment";

const BasicMetaData = (args) => {
  return (
    <Helmet>
      <title>{args.title}</title>
      <meta name="description" content={args.content} />
      <link rel="canonical" href={Environment.HOST + args.link} />
    </Helmet>
  );
};

export default BasicMetaData;
