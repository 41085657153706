import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  NavLink
} from "reactstrap";
import { Environment } from "../../core/env/environment";

const InterestedIn = (props) => {
  return (
        <Card className="mt-1">
          <CardBody className="bg-ec-3 text-light rounded">
            <CardTitle className="bg-light text-dark rounded p-2" tag="h5">
            { props.translator.t('EGYEDI_IGENYEK') }
            </CardTitle>
            <CardSubtitle>{ props.translator.t('NEM_TALALTA_MEG_AMIT_KERESETT') }</CardSubtitle>
            <CardText className="mt-1">
            </CardText>
          </CardBody>
        </Card>
  );
};

export default InterestedIn;
