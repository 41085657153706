import { Environment } from "../../../../core/env/environment";
import { LocalStorageService } from "../../../../core/storage-service/LocalStorageService";
import BasicMetaData from "../../../layout/BasicMetaData";
import GenericNotFound from "../../../misc-components/GenericNotFound";
import axios from "axios";
import { marked } from "marked";
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

const DataProtection = (props) => {
  const ENTITY = "data-protection";
  const [error, setError] = useState();
  const [content, setContent] = useState(null);

  useEffect(() => {
    if(content){
      return;
    }
    fetchData();
  }, [content]);

  function fetchData() {
    const stored = LocalStorageService.find("data_protection");
    if (stored?.attributes) {
      setContent(stored.attributes);

      return;
    }
    axios
      .get(Environment.getEntityTypeApiEndpoint(ENTITY), Environment.getApiRequestHeaders())
      .then(({ data }) => {
        setContent(data.data.attributes);
        LocalStorageService.store("data_protection", data.data);
      })
      .catch((error) => setError(error));
  }

  if (error || !content) {
    return GenericNotFound({
      error: error,
      content: content?.length > 0 ? true : false,
      invalidPath: false,
      translator: props.translator
    });
  }

  return (
    <div>
      <BasicMetaData
        title={content.title}
        content={
          content.title.toLowerCase() + " korisnika i podataka trećih lica"
        }
        link="zastita-podataka"
      />
      <Row className="mt-5 pt-5 pb-5 mb-5">
        <Col lg="12" md="12" sm="12" xs="12">
          <h1>
            {content.title + " "}{" "}
            <small className="text-muted">{content.subTitle}</small>
          </h1>
        </Col>
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          dangerouslySetInnerHTML={{
            __html: marked.parse(content.body),
          }}
        ></Col>
      </Row>
    </div>
  );
};

export default DataProtection;
