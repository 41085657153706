import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Environment } from "../../../core/env/environment";
import { LocalStorageService } from "../../../core/storage-service/LocalStorageService";

import BasicMetaData from "../../layout/BasicMetaData";
import HighLightedInfo from "../content/higlighted-info/HighLightedInfo";
import MainContent from "../content/main/MainContent";
import PackagesAndPricesTeaser from "../content/packages/PackagesAndPricesTeaser";
import HomePageImageSlider from "../galleries/home-slider/HomePageImageSlider";

const HomePage = (props) => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  const ENTITY = "home-page?populate[0]=meta";
  const ENTITY_TWO = "home-page-two";
  const ENTITY_THREE = "home-page-three";
  const [error, setError] = useState();
  const [content, setContent] = useState(null);
  const [contentTwo, setContentTwo] = useState(null);
  const [contentThree, setContentThree] = useState(null);
  const [meta, setMeta] = useState(null)

  useEffect(() => {
    if (content && contentTwo && contentThree && meta) {
      return;
    }
    fetchData();
  },[content,contentTwo,contentThree,meta]);

  function fetchData() {
    const stored = LocalStorageService.find("home_page");
    const storedTwo = LocalStorageService.find("home_page_two");
    const storedThree = LocalStorageService.find("home_page_three");

    if (stored?.attributes && storedThree?.attributes && storedTwo?.attributes) {
      setContent(stored.attributes);
      setMeta(stored.attributes.meta.data.attributes);
      setContentTwo(storedTwo.attributes);
      setContentThree(storedThree.attributes);
      return;
    }
    axios
      .get(
        Environment.getEntityTypeApiEndpoint(ENTITY),
        Environment.getApiRequestHeaders()
      )
      .then(({ data }) => {
        setContent(data.data.attributes);
        setMeta(data.data.attributes.meta.data.attributes);
        LocalStorageService.store("home_page", data.data);
      })
      .catch((error) => setError(error));
    axios
      .get(
        Environment.getEntityTypeApiEndpoint(ENTITY_TWO),
        Environment.getApiRequestHeaders()
      )
      .then(({ data }) => {
        setContentTwo(data.data.attributes);
        LocalStorageService.store("home_page_two", data.data);
      })
      .catch((error) => setError(error));
    axios
      .get(
        Environment.getEntityTypeApiEndpoint(ENTITY_THREE),
        Environment.getApiRequestHeaders()
      )
      .then(({ data }) => {
        setContentThree(data.data.attributes);
        LocalStorageService.store("home_page_three", data.data);
      })
      .catch((error) => setError(error));
  }

  return (
    <div>
      <BasicMetaData
        title={meta?.title ? meta.title : ''}
        content={meta?.content ? meta.content : ''}
        link=""
      />{" "}
      <Row className="text-center">
        <Col lg="12">
          <h1 className="main-content-header-color my-5">
            eCorrect{" "}
            <small className="text-secondary"> { props.translator.t('ONLINE_SZAMLAZO') }</small>
          </h1>
          <h2 className="main-content-header-color">
          <small>{ props.translator.t('ONLINE_SZAMLAZO_DESCRIPTION') }</small>
          </h2>
        </Col>
      </Row>
      <MainContent ismobile={props.ismobile} translator={props.translator} content={content} contentTwo={contentTwo} contentThree={contentThree} error={error} />
        <hr className="app-horizontal-ruler"></hr>
      <HomePageImageSlider ismobile={props.ismobile} translator={props.translator}/>
        <hr className="app-horizontal-ruler"></hr>
      <HighLightedInfo ismobile={props.ismobile} translator={props.translator} />
        <hr className="app-horizontal-ruler"></hr>
      <PackagesAndPricesTeaser translator={props.translator} />
    </div>
  );
};

export default HomePage;
