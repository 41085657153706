import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  ListGroup,
  Row,
} from "reactstrap";

import { Environment } from "../../../../core/env/environment";
import formatPrice from "../../../../core/formatter";
import { LocalStorageService } from "../../../../core/storage-service/LocalStorageService";
import BasicMetaData from "../../../layout/BasicMetaData";
import CommonMarkUpComponent from "../../../misc-components/common-mark-up-component";
import GenericNotFound from "../../../misc-components/GenericNotFound";
import InterestedIn from "../../../misc-components/InterestedIn";
import Service from "../service/Service";

const ExtensionsDetailed = (props) => {
  const ENTITY =
    "extensions-page?populate[0]=meta&populate[1]=extensions.services";

  const [error, setError] = useState();
  const [extensions, setExtensions] = useState([]);
  const [content, setContent] = useState(null);
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    if (extensions.length) {
      return;
    }
    fetchData();
  }, [extensions]);

  function fetchData() {
    const stored = LocalStorageService.find("extensions_detailed");
    if (stored.length) {
      setExtensions(stored.attributes.extensions.data);
      setContent(stored.attributes);
      setMeta(stored.attributes.meta);

      return;
    }

    axios
      .get(
        Environment.getEntityTypeApiEndpoint(ENTITY),
        Environment.getApiRequestHeaders()
      )
      .then(({ data }) => {
        data.data.attributes.extensions.data =
          data.data.attributes.extensions.data.sort((a, b) =>
            a.attributes.weight < b.attributes.weight ? -1 : 1
          );
        setExtensions(data.data.attributes.extensions.data);
        setContent(data.data.attributes);
        setMeta(data.data.attributes.meta.data.attributes);

        LocalStorageService.store("extensions_detailed", data.data);
      })
      .catch((error) => setError(error));
  }

  if (error || !extensions || !content) {
    return GenericNotFound({
      error: error,
      content: extensions?.length > 0 ? true : false,
      invalidPath: false,
      translator: props.translator,
    });
  }

  return (
    <Row className="my-3">
      <BasicMetaData
        title={meta?.title}
        content={meta?.content}
        link={Environment.LANGUAGE_CODE === "HU" ? "modulok" : "moduli"}
      />
      <div className="row justify-content-center">
        <CommonMarkUpComponent {...content}></CommonMarkUpComponent>
        <Col
          lg="3"
          md="6"
          sm="12"
          xs="12"
          className="d-flex flex-column justify-content-around align-items-center"
        >
          <FontAwesomeIcon
            className="package-text-color-basic"
            icon={solid("cubes")}
            size="10x"
          />
        </Col>
      </div>
      <div className="display-flex row justify-content-around mt-5">
        {(() => {
          let container = [];
          if (!extensions?.length) {
            return container;
          }
          extensions.forEach((item, index) => {
            container.push(
              <Col lg="3" md="12" sm="12" xs="12" key={index}>
                <Card className="my-5">
                  <CardBody
                    className="bg-ec-3 text-light"
                    style={{ height: "10rem" }}
                  >
                    <CardTitle
                      className="bg-light rounded text-dark p-2 text-center"
                      tag="h5"
                    >
                      {item.attributes.title}
                    </CardTitle>
                    <CardText className="mt-1 text-center">
                      <Badge color="success p-2">
                        {formatPrice(
                          item.attributes.price,
                          item.attributes.currency
                        )}
                      </Badge>
                    </CardText>
                    <CardSubtitle className="text-center">
                      {item.attributes.description}{" "}
                    </CardSubtitle>
                  </CardBody>
                  <ListGroup
                    flush
                    className="hoverable-item modules-and-prices-service-container"
                  >
                    {(() => {
                      let subContainer = [];
                      if (!item.attributes.services) {
                        return subContainer;
                      }
                      item.attributes.services.data.forEach(
                        (subItem, subIndex) => {
                          subContainer.push(
                            <Service
                              key={subIndex}
                              service={subItem}
                              ismobile={props.ismobile}
                              boldTitle={true}
                              translator={props.translator}
                            ></Service>
                          );
                        }
                      );
                      return subContainer;
                    })()}
                  </ListGroup>
                </Card>
              </Col>
            );
          });
          return container;
        })()}
      </div>
      <div className="row d-flex justify-content-around my-5">
        <Col lg="3" md="4" sm="6" xs="6">
          <InterestedIn translator={props.translator}></InterestedIn>
        </Col>
      </div>
    </Row>
  );
};

export default ExtensionsDetailed;
