import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, NavLink as RRNavLink } from "react-router-dom";
import { Col, Nav, NavItem, Row } from "reactstrap";
import { Environment } from "../../core/env/environment";

const Footer = (props) => {
  return (
    <Row className="bg-dark text-light pt-2 pb-2 footer-row">
      <Col lg="3" md="3" sm="6" xs="12" className="d-flex justify-content-around">
        <div className="row">
        <img
          alt="logo"
          src={Environment.findLogoUrl()}
          style={{
            height: 180,
            width: 'auto',
            'overflowY': 'hidden',
            top: -50
          }}
          className="mt-5"
        />
        </div>
      </Col>
      <Col lg="3" md="2" sm="6" xs="12">
        <h5 className="mb-3">
          <FontAwesomeIcon icon={solid("address-book")} />{" "}{props.translator.t('KAPCSOLAT_ES_WEBSUPPORT')}:
        </h5>
        <p>{props.translator.t('KAPCSOLAT_EMAIL')}</p>
        <p>
        {props.translator.t('KAPCSOLAT_CEG')}
          <br></br>{props.translator.t('KAPCSOLAT_ORSZAG')},<br></br>{props.translator.t('KAPCSOLAT_IRANYITOSZAM')}
          {props.translator.t('KAPCSOLAT_HELYSEG')}, {props.translator.t('KAPCSOLAT_UTCA')}
        </p>
        <p>
        {props.translator.t('KAPCSOLAT_ADOSZAM')}<br></br>
        {props.translator.t('KAPCSOLAT_CEGJEGYZEKSZAM')}<br></br>
        </p>
      </Col>
      <Col lg="3" md="2" sm="6" xs="12">
        <h5 className="mb-3">
          <FontAwesomeIcon icon={solid("map-location-dot")} />{" "}{props.translator.t('OLDAL_TERKEP')}:
        </h5>
        <Nav vertical>
          <NavItem>
            <NavLink
              activeclassname="active"
              to="/"
              tag={RRNavLink}
              className="text-light"
            >eCorrect
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              activeclassname="active"
              to={props.translator.findLinkTranslation('CSOMAGOK_LINK')}
              tag={RRNavLink}>
              {props.translator.t('CSOMAGOK_ES_ARAK')}
            </NavLink>
          </NavItem>
          <NavItem>
          <NavLink
              activeclassname="active"
              to={props.translator.findLinkTranslation('MODULOK_LINK')}
              tag={RRNavLink}>
              {props.translator.findLinkTranslation('MODULOK')}
            </NavLink>
          </NavItem>
          <NavItem>
          <NavLink
              activeclassname="active"
              to={props.translator.findLinkTranslation('KEPTAR_LINK')}
              tag={RRNavLink}>
              {props.translator.findLinkTranslation('KEPTAR')}
            </NavLink>
          </NavItem>
          <NavItem>
          <NavLink
              activeclassname="active"
              to={props.translator.findLinkTranslation('GYIK_LINK')}
              tag={RRNavLink}>
              {props.translator.findLinkTranslation('GYIK')}
            </NavLink>
          </NavItem>
          <NavItem>
          <NavLink
              activeclassname="active"
              to={props.translator.findLinkTranslation('ROLUNK_LINK')}
              tag={RRNavLink}>
              {props.translator.findLinkTranslation('ROLUNK')}
            </NavLink>
          </NavItem>
        </Nav>
      </Col>
      <Col lg="3" md="2" sm="6" xs="12">
        <h5 className="mb-3">
          <FontAwesomeIcon icon={solid("handshake")} />{" "}{props.translator.t('KNOW_HOW')}:
        </h5>
        <p>
          {props.translator.t('PARTNER_CEG_NEV_1')}<br></br>
          {props.translator.t('PARTNER_CEG_CIM_1')}
        </p>
        <p>
        {props.translator.t('PARTNER_CEG_NEV_2')}<br></br>
        {props.translator.t('PARTNER_CEG_CIM_2')}
        </p>
      </Col>
      <Col lg="3" md="2" sm="6" xs="12">
          {" "}
      </Col>
      <Col lg="3" md="2" sm="6" xs="12">
        <h5 className="mb-3">
          <FontAwesomeIcon icon={solid("book")} />{" "}{props.translator.t('DOKUMENTACIOK')}
        </h5>
        <a href={props.translator.findLinkTranslation('FELHASZNALOI_KEZIKONYV_LINK')} target="_blank" rel="noreferrer" className="text-light">
          {props.translator.findLinkTranslation('FELHASZNALOI_KEZIKONYV')}
        </a>{" "}
      </Col>
      <Col lg="3" md="2" sm="6" xs="12">
        <h5 className="mb-3">
          <FontAwesomeIcon icon={solid("book")} />{" "}{props.translator.t('DOKUMENTUMOK')}
        </h5>
        <Nav vertical>
          <NavItem>
            <NavLink
              activeclassname="active"
              to={props.translator.findLinkTranslation('ADATVEDELEM_LINK')}
              tag={RRNavLink}>
              {props.translator.findLinkTranslation('ADATVEDELEM')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              activeclassname="active"
              to={props.translator.findLinkTranslation('SUTIPOLITIKA_LINK')}
              tag={RRNavLink}>
              {props.translator.findLinkTranslation('SUTIPOLITIKA')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              activeclassname="active"
              to={props.translator.findLinkTranslation('ASZF_LINK')}
              tag={RRNavLink}>
              {props.translator.findLinkTranslation('ASZF')}
            </NavLink>
          </NavItem>
        </Nav>
      </Col>
      <Col lg="3" md="2" sm="6" xs="12">
        <h5 className="mb-3">
          <FontAwesomeIcon icon={brands("facebook")} />{" "}{props.translator.t('KOVESSEN_BENNUNKET')}
        </h5>
        <a href={props.translator.findLinkTranslation('FACEBOOK_LINK')} target="_blank" rel="noreferrer" className="text-light">
          {props.translator.findLinkTranslation('FACEBOOK')}
          </a>{" "}
      </Col>
    </Row>
  );
};

export default Footer;
