export default class Translations {

  static HU = {
    ONLINE_SZAMLAZO : ' online számlázó',
    ONLINE_SZAMLAZO_DESCRIPTION: 'Webalkalmazás számlázásra, felvásárlási jegyek készítésére és készletkezelésre szálítólevelekkel',

    CSOMAGOK_ES_ARAK: 'Csomagok és árak',
    CSOMAGOK_ES_ARAK_DESCRIPTION_1: 'Csomagjaink meghatározott számú szolgáltatást és funkciót ölel fel',
    CSOMAGOK_ES_ARAK_DESCRIPTION_2: 'Az árak a csomagokban található szolgáltatások számától és azok fajtáitól függenek.',
    CSOMAGOK_ES_ARAK_DESCRIPTION_3: 'A nagyobb csomagok tartalmazzák a kisebb csomagok szolgáltatásait és funkcióit.',
    CSOMAGOK_ES_ARAK_DESCRIPTION_4: 'Havi és éves előfizetés közül választhat, a szolgáltatások igénybevételének napjától.',
    SZOLGALTATASOK_ES_FUNKCIOK_ELOFIZETOI_CSOMAGONKENT: 'Szolgáltatások és funkciók csomagonként',

    HAVI_ELOFIZETES: 'Havi előfizetés',
    EVES_ELOFIZETES: 'Éves előfizetés',

    HAVI_ELOFIZETES_SHORT: ' /hó',
    EVES_ELOFIZETES_SHORT: ' /év',

    A_NAGYOBB_CSOMAG_MAGABA_FOGLALJA_A_KISEBB_CSOMAG_SZOLGALTATASAIT: 'A nagyobb csomag tartalmazza a kisebb csomag szolgáltatásait és funkcióit.',
    OPCIONALIS_HAVI_VAGY_EVES_ELOFIZETES: 'A {0} csomag havi és éves előfizetés alapján elérhető, a szolgáltatások igénybevételének napjától.',

    A_CSOMAG_SZOLGALTATASAI: 'A csomag szolgáltatásai',
    AZ_ALKALMAZAS_ELERHETO_DEMO_MODBAN_IS: 'Az alkalmazás DEMO módban is elérhető',

    REGISZTRACIO: 'Regisztráció',
    KOTELEZETTSEGEK_NELKUL: 'Kötelezettségek nélkül',
    DEMO_STATUSZBAN: 'DEMO módban ',
    MINDEN_SZOLGALTATAS_ELERHETO: ' minden szolgáltatás és funkció elérhető',
    A_REGISZTRACIOVAL_AUTOMATIKUSAN_INGYENES_CSOMAGOT_KAP: 'A regisztrációval automatikusan ingyenes csomag előfizetői státuszba kerül.',
    AZ_ALKALMAZASBAN_IGENYELHET_MASIK_CSOMAGOT: 'Az alkalmazásban igényelhet csomag váltást.',
    REGISZTRACIO_ES_DEMO: 'Regisztráció és DEMO',
    BEJELENTKEZES: 'Bejelentkezés',

    EGYEDI_IGENYEK: 'Egyedi igények',
    NEM_TALALTA_MEG_AMIT_KERESETT : 'Nem találta az amit keresett?',

    MODULOK: 'Modulok',
    MODULOK_SUBTITLE: 'Kiegészítő szolgáltatások és funkciók',
    MODULOK_DESCRIPTION_1 : 'Az előfizetői csomag kibővítését szolgálját további szolgáltatásokkal és funkciókkal',
    MODULOK_DESCRIPTION_2 : 'A legtöbb modul BUSINESS PLUS előfizetői csomagot igényel.',
    MODULOK_DESCRIPTION_3 : 'A feltüntetett ár egyszer fizetendő, minden regisztrált gazdasági alany után.',
    
    MICRO_ERP: 'Mini ERP',
    MIT_KAP_CSEREBE: 'Mit kínálunk',
    MIT_KAP_CSEREBE_DESCRIPTION: 'Webalkalmazást a gördülékeny adminisztrációért',

    KIEMELT_JELLEMZOK: 'Kiemelt tulajdonságok',

    ELOFIZETOI_CSOMAGOK: 'Elofizetői csomagok',
    ELOFIZETOI_CSOMAGOK_DESCRIPTION : 'szolgáltatások és funkciók előfizetői csomagonként és modulonként csoportosítva',

    GYIK: 'Gyakori kérdések (GYIK)',

    KERDESE_VAN: 'Kérdése van',
    KERDESE_VAN_SUBTITLE: 'További információra van szüksége ?',

    UTOLSO_MODOSITAS_DATUMA: 'Az utolsó módosítás dátuma',

    EMAIL_CIM: 'Email cím',
    EMAIL_CIM_NEM_MEGFELELO: 'Nem megfelelő email cím',
    EMAIL_CIM_MINTA: 'email.cim@emailserver.valami',
    EMAIL_UZENET: 'Üzenet',
    EMAIL_SZOVEGE_HIANYZIK: 'Nincs szöveg az üzenetben',
    EMAIL_SZOVEGE: 'Az email szövege ...',
    EMAIL_KULDESE: 'Küldés',
    EMAIL_GOOGLE_RECAPTCHA_1: 'Ez az oldal reCAPTCHA védelem alatt áll a ',
    EMAIL_GOOGLE_RECAPTCHA_2: 'Google Általános szabályai',
    ES: 'és',
    AZ_ALTALANOS_SZERZODESI_FELTETELEKKEL: 'és a fejlesztő Általános szerződési feltételei szerint',
    FORDULJON_HOZZANK: 'Forduljon hozzánk',
    FORDULJON_HOZZANK_DESCRIPTION_1: 'Minden kérdésére és javaslatára kiváncsiak vagyunk',
    FORDULJON_HOZZANK_DESCRIPTION_2: 'Kapcsolat, egyedi igények, javaslatok, ötletek, hiányosságok, hibabejelentések, ajánlatok',

    OOPS_SOMETHING_WENT_WRONG: 'Hoppá, valami nincs rendben ...',
    HIBA: 'Hiba',
    A_KERT_OLDAL_NEM_LETEZIK_VAGY_SZERKESZTES_ALATT_ALL: 'ez az oldal nem létezik, szerkesztés alatt ál, vagy törlésre került',

    KAPCSOLAT_ES_WEBSUPPORT: 'Kapcsolat és Websupport',
    OLDAL_TERKEP: 'Oldalunk térképe',
    KNOW_HOW: 'Partnerek',
    DOKUMENTACIOK: 'Dokumentációk',
    DOKUMENTUMOK: 'Dokumentumok',
    KOVESSEN_BENNUNKET: 'Kövessen bennünket:',

    KAPCSOLAT_EMAIL: 'websupport@ecorrect.hu',
    KAPCSOLAT_CEG: 'I.T.C. Kft',
    KAPCSOLAT_ORSZAG: 'Magyarország',
    KAPCSOLAT_HELYSEG: 'Szeged',
    KAPCSOLAT_IRANYITOSZAM: '6726',
    KAPCSOLAT_UTCA: 'Fő Fasor 52.',
    KAPCSOLAT_ADOSZAM: '',
    KAPCSOLAT_CEGJEGYZEKSZAM: '',


    PARTNER_CEG_NEV_1: 'I.T.C. Kft',
    PARTNER_CEG_CIM_1: 'Magyarország, 6726 Szeged, Fő Fasor 52.',

    PARTNER_CEG_NEV_2: '',
    PARTNER_CEG_CIM_2: '',

    RESZLETEK: 'Részletek',

    ELOZO: 'Előző',
    KOVETKEZO: 'Következő',
    
    FREE: 'FREE',
    BASIC: 'BASIC',
    PRO: 'PRO',
    BUSINESS: 'BUSINESS',
    BUSINESS_PLUS: 'BUSINESS PLUS',

  }

  static RS = {
    ONLINE_SZAMLAZO : ' onlajn fakturisanje',
    ONLINE_SZAMLAZO_DESCRIPTION: 'Web Aplikacija za izradu računa i otpremnica i rad sa Efaktura podacima',

    CSOMAGOK_ES_ARAK: 'Paketi i cene',
    CSOMAGOK_ES_ARAK_DESCRIPTION_1: 'Paketi obuhvataju određeni broj usluga i funkcija.',
    CSOMAGOK_ES_ARAK_DESCRIPTION_2: 'Cene su određene prema broju i vrsti usluga i funkcija u paketu.',
    CSOMAGOK_ES_ARAK_DESCRIPTION_3: 'Viši paket obuhvata sve usluge i funkcije nižeg paketa',
    CSOMAGOK_ES_ARAK_DESCRIPTION_4: 'Cena paketa može da se plati mesečno ili godišnje, od zasnivanja pretplatničkog odnosa.',
    SZOLGALTATASOK_ES_FUNKCIOK_ELOFIZETOI_CSOMAGONKENT: 'Usluge i funkcije po pretplatničkim paketima',

    HAVI_ELOFIZETES: 'Mesečni plan',
    EVES_ELOFIZETES: 'Godišnji plan',
    HAVI_ELOFIZETES_SHORT: ' /mes.',
    EVES_ELOFIZETES_SHORT: ' /god.',

    A_NAGYOBB_CSOMAG_MAGABA_FOGLALJA_A_KISEBB_CSOMAG_SZOLGALTATASAIT: 'Viši paket obuhvata sve usluge i funkcije nižeg paketa',
    OPCIONALIS_HAVI_VAGY_EVES_ELOFIZETES: 'Cena {0} paketa može da se plati mesečno ili godišnje, od zasnivanja pretplatničkog odnosa.',

    A_CSOMAG_SZOLGALTATASAI: 'Usluge paketa',
    AZ_ALKALMAZAS_ELERHETO_DEMO_MODBAN_IS: 'Aplikacija dostupna i u demo statusu',

    REGISZTRACIO: 'Registracija',
    KOTELEZETTSEGEK_NELKUL: 'Bez obaveza',
    DEMO_STATUSZBAN: 'U Demo statusu ',
    MINDEN_SZOLGALTATAS_ELERHETO: ' sve usluge i funkcije su dostupne',
    A_REGISZTRACIOVAL_AUTOMATIKUSAN_INGYENES_CSOMAGOT_KAP: 'Registracijom automatski dobijate DEMO BUSINESS PLUS paket. Aktivacijom prelazite u STARTER paket.',
    AZ_ALKALMAZASBAN_IGENYELHET_MASIK_CSOMAGOT: 'U aplikaciji, nakon aktivacije možete zahtevati drugi pretplatnički paket',
    REGISZTRACIO_ES_DEMO: 'Registracija i Demo',
    BEJELENTKEZES: 'Prijava',

    EGYEDI_IGENYEK: 'Specifične potrebe i zahtevi',
    NEM_TALALTA_MEG_AMIT_KERESETT : 'Niste našli ono što ste tražili?',

    MODULOK: 'Moduli',
    MODULOK_SUBTITLE: ' Dodatne usluge i funkcije po pretplatničkim modulima',
    MODULOK_DESCRIPTION_1 : 'Moduli predstavljaju nadogradnju pretplatničkih paketa sa dodatnim uslugama i funkcijama.',
    MODULOK_DESCRIPTION_2 : 'Svi moduli zahtevaju pretplatu najmanje za BUSINESS PLUS paket.',
    MODULOK_DESCRIPTION_3 : 'Cena modula se plaća posebno za svaki privredni subjekat u okviru korisničkog naloga i to jednokratno.',
    
    MICRO_ERP: 'Mikro ERP',
    MIT_KAP_CSEREBE: 'Šta nudimo',
    MIT_KAP_CSEREBE_DESCRIPTION: 'Aplikaciju za lakšu administraciju',

    KIEMELT_JELLEMZOK: 'Ključne karakteristike',

    ELOFIZETOI_CSOMAGOK: 'Pretplatnički paketi',
    ELOFIZETOI_CSOMAGOK_DESCRIPTION : 'usluge i funkcije grupisane po paketima i modulima',

    GYIK: 'Pitanja i odgovori',

    KERDESE_VAN: 'Imate pitanja',
    KERDESE_VAN_SUBTITLE: 'Bitna informacija nije navedena ...',

    UTOLSO_MODOSITAS_DATUMA: 'Datum poslednje izmene',

    EMAIL_CIM: 'Email adresa',
    EMAIL_CIM_NEM_MEGFELELO: 'Neodgovarajući format e-mail adrese posetioca',
    EMAIL_CIM_MINTA: 'from.adresa@emailserver.nešto',
    EMAIL_UZENET: 'Poruka',
    EMAIL_SZOVEGE_HIANYZIK: 'Tekst poruke nedostaje',
    EMAIL_SZOVEGE: 'Tekst emaila ...',
    EMAIL_KULDESE: 'Pošalji',
    EMAIL_GOOGLE_RECAPTCHA_1: 'Ova stranica je pod zaštitom Google reCAPTCHA alata po',
    EMAIL_GOOGLE_RECAPTCHA_2: 'Google Pravilima zaštite podataka',
    ES: 'i',
    AZ_ALTALANOS_SZERZODESI_FELTETELEKKEL: 'Opštim uslovima',
    FORDULJON_HOZZANK: 'Obratite se nam',
    FORDULJON_HOZZANK_DESCRIPTION_1: 'Za sva pitanja i ideje stojimo Vam na raspolaganju',
    FORDULJON_HOZZANK_DESCRIPTION_2: 'Kontakt, specifične potrebe, predlozi, ideje, nedostaci, prijave, posebni zahtevi i ponude',

    OOPS_SOMETHING_WENT_WRONG: 'Ups, nešto nije u redu ...',
    HIBA: 'Greška',
    A_KERT_OLDAL_NEM_LETEZIK_VAGY_SZERKESZTES_ALATT_ALL: 'ova stranica ne postoji, u toku je izmena ili sadržaj je brisan',

   
        

    KAPCSOLAT_ES_WEBSUPPORT: 'Kontakt i podrška',
    OLDAL_TERKEP: 'Mapa sajta',
    KNOW_HOW: 'Partneri',
    DOKUMENTACIOK: 'Dokumentacije',
    DOKUMENTUMOK: 'Dokumenti',
    KOVESSEN_BENNUNKET: 'Pratite nas:',

    KAPCSOLAT_EMAIL: 'websupport@ecorrect.rs',
    KAPCSOLAT_CEG: 'I.T.C. Kft',
    KAPCSOLAT_ORSZAG: 'Magyarország',
    KAPCSOLAT_HELYSEG: 'Szeged',
    KAPCSOLAT_IRANYITOSZAM: '6726',
    KAPCSOLAT_UTCA: 'Fő Fasor 52.',
    KAPCSOLAT_ADOSZAM: '',
    KAPCSOLAT_CEGJEGYZEKSZAM: '01-09-275338',


    PARTNER_CEG_NEV_1: 'Unisoft doo',
    PARTNER_CEG_CIM_1: 'Srbija, 24420 Kanjiža, Doža Đerđa 9.',

    PARTNER_CEG_NEV_2: 'I.T.C. Kft',
    PARTNER_CEG_CIM_2: 'Mađarska, Segedin 6726, Fő Fasor 52.',

    RESZLETEK: 'Detalji',

    ELOZO: 'Prethodna',
    KOVETKEZO: 'Sledeća',

    FREE: 'STARTER',
    BASIC: 'BASIC',
    PRO: 'PRO',
    BUSINESS: 'BUSINESS',
    BUSINESS_PLUS: 'BUSINESS PLUS',
  }

}