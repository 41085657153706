function formatPrice(number, currency){

  if(!number && number !== 0 && number !== '0'){
    return '';
  }

  return number.toLocaleString('sr-SR', {maximumFractionDigits:2}) + ' ' + currency;
}

export default formatPrice;
