import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { marked } from "marked";
import { useEffect, useState } from "react";
import { CardBody, CardSubtitle, CardText, CardTitle, Col, Row } from "reactstrap";

import { Environment } from "../../../../core/env/environment";
import { LocalStorageService } from "../../../../core/storage-service/LocalStorageService";
import GenericNotFound from "../../../misc-components/GenericNotFound";

const HighLightedInfo = (props) => {
  const ENTITY = "high-lights";
  const [error, setError] = useState();
  const [highLights, setHighLights] = useState([]);

  useEffect(() => {
    if (highLights.length || error) {
      return;
    }
    fetchData();
  }, [highLights, error]);

  function fetchData() {
    const stored = LocalStorageService.find("high_lights");
    if (stored.length) {
      setHighLights(stored);

      return;
    }
    axios
      .get(
        Environment.getEntityTypeApiEndpoint(ENTITY),
        Environment.getApiRequestHeaders()
      )
      .then(({ data }) => {
        data.data = data.data.sort((a, b) =>
          a.attributes.weight < b.attributes.weight ? -1 : 1
        );
        setHighLights(data.data);
        LocalStorageService.store("high_lights", data.data);
      })
      .catch((error) => setError(error));
  }

  if (error || !highLights || highLights?.length === 0) {
    return GenericNotFound({
      error: error,
      content: highLights?.length > 0 ? true : false,
      invalidPath: false,
      translator: props.translator
    });
  }

  return (
    <div>
      <Row className="align-middle my-5">
        <Col lg="12" md="12" sm="12" xs="12">
          <h2 className="m-5 text-center main-content-header-color">{props.translator.t('KIEMELT_JELLEMZOK')}</h2>
        </Col>
      </Row>
      <Row className="my-5 pt-5 d-flex justify-content-around">
        {(() => {
          let container = [];
          let icon = "";
          highLights.forEach((highlight, index) => {
            icon = "fa-solid fa-" + highlight.attributes.fontawesomeIcon;
            container.push(
              <Col lg="4" md="4" sm="6" xs="6" className="mb-5 rounded p-3 m-" key={index}>
                <FontAwesomeIcon
                  icon={icon}
                  size="4x"
                  className="package-text-color-basic rounded"
                />
                <CardBody className="mt-3">
                  <CardTitle tag="h5" className="main-content-header-color">
                    {highlight.attributes.title}
                  </CardTitle>
                  <CardSubtitle className="mb-2 text-muted" tag="h6">
                    {highlight.attributes.subTitle}
                  </CardSubtitle>
                  <CardText dangerouslySetInnerHTML={{__html: marked.parse(highlight.attributes.body)}}></CardText>
                </CardBody>
              </Col>
            );
          });
          return container;
        })()}
      </Row>
    </div>
  );
};

export default HighLightedInfo;
