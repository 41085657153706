import { Environment } from "../../../../core/env/environment";
import { LocalStorageService } from "../../../../core/storage-service/LocalStorageService";
import BasicMetaData from "../../../layout/BasicMetaData";
import GenericNotFound from "../../../misc-components/GenericNotFound";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { NavLink, NavLink as RRNavLink } from "react-router-dom";
import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Row } from "reactstrap";
import CommonMarkUpComponent from "../../../misc-components/common-mark-up-component";

const Faqs = (props) => {
  const ENTITY = "gyik?populate=*";
  const [error, setError] = useState();
  const [questions, setQuestions] = useState([]);
  const [content, setContent] = useState(null)
  const [meta, setMeta] = useState(null)

  useEffect(() => {
    if (questions.length) {
      return;
    }
    fetchData();
  }, [questions]);

  function fetchData() {
    const stored = LocalStorageService.find(ENTITY);
    if (stored.length) {
      setQuestions(stored.attributes.questions.data);
      setContent(stored.attributes);
      setMeta(stored.attributes.meta);

      return;
    }

    axios
      .get(
        Environment.getEntityTypeApiEndpoint(ENTITY),
        Environment.getApiRequestHeaders()
      )
      .then(({ data }) => {
        data.data.attributes.questions.data = data.data.attributes.questions.data.sort(
          (a, b) => (a.attributes.weight < b.attributes.weight ? -1 : 1)
        );
        setQuestions(data.data.attributes.questions.data);
        setContent(data.data.attributes);
        setMeta(data.data.attributes.meta);
        LocalStorageService.store(ENTITY, data.data);
      })
      .catch((error) => setError(error));
  }

  if (error || !questions) {
    return GenericNotFound({
      error: error,
      content: questions?.length > 0 ? true : false,
      invalidPath: false,
      translator: props.translator
    });
  }

  function getIconAsImage(){
      return props.ismobile ? (
        <></>
      ) : (
        <Col lg="3" md="6" sm="12" xs="12" className="d-flex flex-column justify-content-around align-items-center">
          <FontAwesomeIcon className="package-text-color-basic" icon={solid("question")} size="10x"/>
        </Col>
      );
  }

  if(!content){
    return <Row></Row>
  }

  return (
    <Row className="pb-5">
      <BasicMetaData
        title={meta.title}
        content={meta.content}
        link={Environment.LANGUAGE_CODE === 'HU' ? 'gyik' : 'pitanja-i-odgovori'}
      />
      <Row className="my-3 justify-content-center">
        <CommonMarkUpComponent {...content}></CommonMarkUpComponent>
        {getIconAsImage()}
      </Row>
      <Row className="my-3 justify-content-center">
      <Col lg="6" md="6" sm="12" xs="12">
        <p>
            <Button
              size="sm"
              color="success"
              onClick={(event) =>
                (window.location.href = Environment.FELHASZNALOI_KEZIKONYV)
              }
            >{props.translator.findLinkTranslation('FELHASZNALOI_KEZIKONYV')}{" "}
              {" "}
              <FontAwesomeIcon icon={solid("right-to-bracket")} />
            </Button>
          </p>
        </Col>
      </Row>
      <Row>
        {(() => {
          let container = [];
          questions.forEach((item, index) => {
            container.push(
              <Col lg="4" md="4" sm="6" xs="12" key={index}>
                <ListGroupItem className="mt-5 p-2 bg-light text-light rounded">
                  <ListGroupItemHeading className="bg-ec-3 rounded p-2">
                    {item.attributes.title}
                  </ListGroupItemHeading>
                  <ListGroupItemText className="text-dark">
                    {item.attributes.description}
                  </ListGroupItemText>
                </ListGroupItem>
              </Col>
            );
          });
          return container;
        })()}
      </Row>
      <Row>
        <Col lg={{ size: 3 }} md={{ size: 6 }} sm="12" xs="12">
          <Card className="mt-5">
            <CardBody className="bg-ec-3 text-light rounded">
              <CardTitle className="bg-light text-dark rounded p-2" tag="h5">
                {props.translator.t('KERDESE_VAN')}?{" "}
              </CardTitle>
              <CardSubtitle>{props.translator.t('KERDESE_VAN_SUBTITLE')}{" "}</CardSubtitle>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Row>
  );
};

export default Faqs;
