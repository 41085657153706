import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Environment } from "../../core/env/environment";
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useCallback, useState } from "react";
import { ValidatorPattern } from "../../core/ValidatorPattern";
import axios from "axios";

const SoftwareSourceCodeForSell = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubmited, setIsSubmited] = useState(false);

  const isNameValid = useCallback(() => {
    return name.length > 0;
  }, [name]);

  const isEmailValid = useCallback(() => {
    return ValidatorPattern.EMAIL.test(email);
  }, [email]);

  const isPhoneValid = useCallback(() => {
    return phone.length > 0;
  }, [phone]);

  const resetForm = useCallback(() => {
    setName("");
    setEmail("");
    setPhone("");
    setError("");
    setSuccess("");
    setIsSubmited(false);
  }, []);

  const handleSubmit = useCallback(() => {
    setIsSubmited(true);

    if (!isNameValid() || !isEmailValid() || !isPhoneValid()) {
      setError("Postoji greška. Molimo vas da pregledate svoje unose!");
      return;
    }

    axios
        .post(
          Environment.getApiEndpoint() + "interests",
          { data: {
            'name': name,
            'email': email,
            'phone': phone,
          }},
          Environment.getApiRequestHeaders()
        )
        .then((response) => {
          if (response.data.error) {
            setError(response.data.error.message);
            return;
          }
        })
        .catch((error) => {
          setError(
            "Servis trenutno nije dostupan. Molimo Vas, pokušajte kasnije!"
          );
          console.log('error', error);
        });

    resetForm();
    setSuccess("Uspešno ste poslali zahtev. Uskoro ćemo vas kontaktirati.");
  }, [resetForm, isNameValid, isEmailValid, isPhoneValid, name, email, phone]);

  return (
    <div className="p-1 xl-p-5 text-center">
      <img
        alt="logo"
        src={Environment.findLogoUrl()}
        style={{
          height: 250,
          width: "auto",
          overflowY: "hidden",
          marginBottom: "3rem",
        }}
      />
      <h1 className="fw-bold">
        Otkrijte puni potencijal Online Fakturacijskog Programa!
      </h1>
      <h2 className="text-secondary my-3">
        Nudimo vam rešenje s kojim možete ući na tržište online fakturacija ili
        čak da učinkovitije upravljate svojim poslovnim procesima, dok
        zadržavate potpunu kontrolu i fleksibilnost.
      </h2>

      <h3 className="my-5 fw-bold">
        Zašto odabrati Online Fakturacijski Program?
      </h3>
      <Row className="my-5 d-flex align-items-stretch">
        <Col className="mx-3 d-flex flex-column justify-content-between">
          <div>
            <FontAwesomeIcon
              icon={["fas", "expand"]}
              size="4x"
              className="text-success mb-3"
            />
            <h4>Robusna funkcionalnost</h4>
          </div>
          <p>
            Kombinacija Java Spring backend-a i Angular frontend-a osigurava
            pouzdanost i performanse sistema.
          </p>
        </Col>
        <Col className="mx-3 d-flex flex-column justify-content-between">
          <div>
            <FontAwesomeIcon
              icon={["fas", "fingerprint"]}
              size="4x"
              className="text-success mb-3"
            />
            <h4>Jedinstvena demonstraciona stranica</h4>
          </div>
          <p>
            Demonstraciona stranica napravljena uz pomoć Strapi CMS-a i
            ReactJS-a omogućava prikaz funkcionalnosti i prednosti softvera
            zainteresovanima.
          </p>
        </Col>
        <Col className="mx-3 d-flex flex-column justify-content-between">
          <div>
            <FontAwesomeIcon
              icon={["fas", "check"]}
              size="4x"
              className="text-success mb-3"
            />
            <h4>Potpuna preuzimanje brenda</h4>
          </div>
          <p>
            Korišćenjem softvera dobijate potpuno pravo na upravljanje i
            prilagođavanje sistema, kao i kreiranje brenda u skladu sa
            identitetom vaše kompanije.
          </p>
        </Col>
      </Row>

      <h3 className="mt-5 mb-3 fw-bold">Tako se oblikuje ekosistem?</h3>
      <Row>
        <Col>
          <p className="lead">
            Online Fakturacijski Program je kompleksan sistem koji se sastoji od
            više komponenti. Na backend-u se koristi Java Spring, dok je za
            frontend korišćen Angular. Za upravljanje sadržajem koristi se
            Strapi CMS, a za demonstraciju funkcionalnosti ReactJS.
          </p>
          <img
            alt="ecorrect ekosistem"
            src="/images/ecosystem.png"
            style={{ width: "300px", height: "auto" }}
          />
        </Col>
      </Row>

      <h3 className="mt-5 mb-3 fw-bold">Kako doći do softvera?</h3>
      <Row>
        <Col>
          <p className="lead">
            Molimo vas, kontaktirajte nas putem donjeg obrasca, i uskoro ćemo
            stupiti u kontakt s vama.
          </p>
        </Col>
      </Row>

      <Row>
        <Col className="mx-auto" xs={12} lg={6}>
          <Form className="my-5 bg-primary p-3 rounded">
            { success ? <Alert color="success">{success}</Alert> : "" }
            { error ? <Alert color="danger">{error}</Alert> : "" }

            <FormGroup className="d-flex flex-column justify-content-start text-start">
              <Label for="name" className="text-light fw-bold">
                Ime
              </Label>
              <Input
                name="name"
                value={name}
                invalid={isSubmited && !isNameValid()}
                onChange={(e) => setName(e.target.value)}
                type="text"
              />
            </FormGroup>

            <FormGroup className="d-flex flex-column justify-content-start text-start">
              <Label for="email" className="text-light fw-bold">
                Email
              </Label>
              <Input
                name="email"
                value={email}
                invalid={isSubmited && !isEmailValid()}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
              />
            </FormGroup>

            <FormGroup className="d-flex flex-column justify-content-start text-start">
              <Label for="phone" className="text-light fw-bold">
                Telefon
              </Label>
              <Input
                name="phone"
                value={phone}
                invalid={isSubmited && !isPhoneValid()}
                onChange={(e) => setPhone(e.target.value)}
                type="phone"
              />
            </FormGroup>

            <Button
              color="success"
              onClick={handleSubmit}
              className="mt-1 mb-2 w-100 fw-bold"
            >
              Pošalji
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default SoftwareSourceCodeForSell;
