import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { ListGroupItem } from "reactstrap";
import ServiceDetail from "./ServiceDetail";

function Service(props) {
  const [isShown, setIsShown] = useState(false);

  function handleCallback() {
    setIsShown(false);
  }

  const iconColor = "all-previous-services-" + props.color;

  function findPayedPackageComponent() {
    let payedPackage = (
      <FontAwesomeIcon className="text-success" size="2x" icon={solid("check-double")} />
    );
    if (props.isPayedPackage && props.firstService) {
      payedPackage = (
        <FontAwesomeIcon
          size={
            props.payedIconSize === undefined ? "1x" : props.payedIconSize + "x"
          }
          className={iconColor}
          icon={solid("hand-point-left")}
        />
      );
    }
    return payedPackage;
  }

  const isBoldTitle =
    (props.isPayedPackage && props.firstService) || props.boldTitle;

  return (
    <ListGroupItem
      className="small packages-teaser-service-item bg-light py-3"
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      {findPayedPackageComponent()}{" "}
      {isBoldTitle ? (
        <strong>{props.service.attributes.title}</strong>
      ) : (
        <span>{props.service.attributes.title}</span>
      )}
      <br></br>
      <em>{props.service.attributes.description}</em>
      {isShown || props.ismobile ? (
        <ServiceDetail
          key={props.service.id + "_s"}
          service={props.service}
          parentCallBack={handleCallback}
          translator={props.translator}
        ></ServiceDetail>
      ) : (
        <></>
      )}
    </ListGroupItem>
  );
}

export default Service;
