import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import { Container, Row } from "reactstrap";

import { Environment } from "../../core/env/environment";
import Footer from "./Footer";
import NavigationBar from "./NavigationBar";
import ScrollToTopButton from "./ScrollToTopButton";

const Layout = (props) => {
  return (
    <>
      <Helmet>
        <title>{Environment.SITE_NAME}</title>
        <link rel="canonical" href={Environment.HOST} />
        <meta name="theme-color" content="#"></meta>
      </Helmet>
      <nav>
        <NavigationBar translator={props.translator}></NavigationBar>
      </nav>
      <main>
        <Container style={{ minHeight: "100vh" }}>
          <Outlet />
        </Container>
        <Container className="d-flex justify-content-end">
          <Row>
            <ScrollToTopButton/>
          </Row>
            
        </Container>
      </main>
      <footer>
        <Container fluid className="mt-5">
          <Footer translator={props.translator} />
        </Container>
      </footer>
    </>
  );
};

export default Layout;
