import { useState } from "react";
import CookieConsent from "react-cookie-consent";
import { Col, Form, FormGroup, FormText, Input, Label, Row } from "reactstrap";

import { LocalStorageService } from "../../core/storage-service/LocalStorageService";

const CookieBar = () => {
  const [cookies, setCookies] = useState({
    contentData: true,
    analytics: true,
  });

  function configureLocalStorageUsage() {
    LocalStorageService.store("storage_usage", cookies);
    LocalStorageService.clear();
  }

  return (
    <CookieConsent
      buttonText={"Prihvatam"}
      className="bg-dark text-light"
      style={{opacity: .95}}
      buttonStyle={{
        background: "#198754",
        color: "#ffffff",
        fontSize: "1rem",
      }}
      enableDeclineButton
      declineButtonText={"Ne prihvatam"}
      declineButtonStyle={{ background: "#005082" }}
      location="bottom"
      cookieName="ecorrect_cookie_consent"
      expires={150}
      debug={false}
      onAccept={() => {
        configureLocalStorageUsage();
      }}
    >
      <Row className="d-flex justify-content-end">
        <Col lg="6" md="12" sm="12" xs="12">
          <p>
            Ova web stranica upotrebljava kolačiće za bolje korisničke doživalje.{" "}
          </p>
          <Form>
            <h4>Saglasnost:{' '}</h4>
            <FormGroup switch>
              <Input
                type="switch"
                checked={cookies.contentData}
                onClick={() => {
                  setCookies({
                    ...cookies,
                    contentData: !cookies.contentData,
                  });
                }}
                onChange={() => {
                  // ignored
                }}
              />
              <Label check>
                Čuvanje podataka dinamičkih sadržaja i slika stranice na vašem uređaju{' '}
              </Label>
              <FormText><br></br>Stranice se brže učitavaju{' '}</FormText>
            </FormGroup>
            <FormGroup switch>
              <Input
                type="switch"
                checked={cookies.analytics}
                onClick={() => {
                  setCookies({
                    ...cookies,
                    analytics: !cookies.analytics,
                  });
                }}
                onChange={() => {
                  // ignored
                }}
              />
              <Label check>
                Google Analytics - podaci koje prikuplja Google o posetiocima
                sajta za statističke potrebe
              </Label>
              <FormText><br></br>Statističke analize posećivanosti sajta{' '}</FormText>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </CookieConsent>
  );
};

export default CookieBar;
