import { Col, Row } from "reactstrap";

const GenericNotFound = (args) => {

  const error = args?.error && !args.invalidPath;
  const invalidPath = args.invalidPath;
  const missingContent = !args?.error && !args?.content && !invalidPath
  
  if (error) {
    return (
      <Row className="mt-5 pt-5 justify-content-around">
        <Col lg="3" md="3" sm="6" xs="6" className="mt-5 pt-5">
          <h3>{args.translator.t('OOPS_SOMETHING_WENT_WRONG')}{" "}</h3>
          <ul>
            <li>{args.translator.t('HIBA')}{" "}: {args.error.message}</li>
          </ul>
        </Col>
      </Row>
    );
  }
  if (missingContent) {
    return (
      <></>
    );
  }

  if (invalidPath) {
    return (
      <Row className="mt-5 pt-5 justify-content-around">
        <Col lg="3" md="3" sm="6" xs="6" className="mt-5 pt-5">
          <h3>{args.translator.t('OOPS_SOMETHING_WENT_WRONG')}{" "}</h3>
          <ul>
            <li>{args.translator.t('A_KERT_OLDAL_NEM_LETEZIK_VAGY_SZERKESZTES_ALATT_ALL')}{" "}</li>
          </ul>
        </Col>
      </Row>
    );
  }

  return (<> </>);
};

export default GenericNotFound;
