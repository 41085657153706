import { useState } from "react";
import { Card, CardImg, CardImgOverlay, Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import { Environment } from "../../../../core/env/environment";
import BasicMetaData from "../../../layout/BasicMetaData";
import ImageCarousel from "./ImageCarousel";
import '../home-slider/HomePageImageSlider.css';


const Gallery = (props) => {
  const [componentState, setComponentState] = useState({
    isModalOpen: false,
    images: props.images,
    imagesToShow: props.imagesToShow,
    limit: props.limit,
    currentIndex: 0,
  });

  function toggleModal() {
    setComponentState({
      ...componentState,
      isModalOpen: !componentState.isModalOpen,
    });
  }

  function showModalImage(imageIdParam) {
    setComponentState({
      ...componentState,
      currentIndex: imageIdParam,
      isModalOpen: !componentState.isModalOpen,
    });
  }

  const images = componentState.images.slice(0, componentState.imagesToShow);
  const hasMore =
    componentState.images.length !== 0
      ? componentState.images.length - componentState.imagesToShow
      : 0;

  function createThumbnails(images) {
    const thumbnails = images.map((image, index) => (
      <Col
        md="2"
        className="my-2"
        key={index}
        onClick={() => showModalImage(index)}
      >
        <Card>
          <CardImg
            src={
              Environment.getPublicEndpoint() + image.thumbnail.url.substring(1)
            }
            alt={image.alt + ", " + image.description}
            className="hoverable-item"
          />
          {hasMore !== 0 && index === componentState.imagesToShow - 1 ? (
            <CardImgOverlay className="d-flex align-items-center flex-column justify-content-center text-center text-white">
              <h5 className="mb-0">{hasMore}</h5>
              <small> Još slike ... </small>
            </CardImgOverlay>
          ) : (
            <></>
          )}
        </Card>
      </Col>
    ));

    return thumbnails;
  }

  let title = 'Slike';
  let subTitle = 'o aplikaciji';
  let description = 'Fakturisanje u slikama';
  let modalHeaderText = 'Galerija';

  if(Environment.LANGUAGE_CODE === 'HU'){
    title = 'Képek';
    subTitle = 'az alkalmazásról';
    description = 'Számlázás képekben';
    modalHeaderText = 'Képtár';
  }

  return (
    <Container className="mt-5">
      <BasicMetaData
        title={title + ' ' + subTitle}
        content={title + ' ' + subTitle + ". " + description}
        link="slike-o-aplikaciji"
      />
      <Row className="mb-5 align-middle mb-5 pb-5">
        <Col lg="12" md="12" sm="12" xs="12">
          <h1 className="text-center main-content-header-color">
            {title}{' '}<small className="text-muted">{subTitle}</small>
          </h1>
          <p className="text-center lead">{description}
          </p>
        </Col>
      </Row>
      <Row className="pt-3 pb-3 rounded">
        {createThumbnails(images)}
      </Row>
      <Modal
        className="modal-lg"
        isOpen={componentState.isModalOpen}
        toggle={toggleModal}
      >
        <ModalHeader className="p-3">{modalHeaderText}</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <ImageCarousel
                translator={props.translator}
                images={componentState.images}
                currentIndex={componentState.currentIndex}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default Gallery;
