export class LocalStorageService {

    static find(key) {
        if (LocalStorageService.isLocalStorageDisabled()) {
            return [];
        }
        if (localStorage.getItem(key)) {
            return JSON.parse(localStorage.getItem(key));
        }
        return [];
    }

    static isLocalStorageDisabled() {
        if (!localStorage.getItem('storage_usage')) {
            return true;
        }

        return !JSON.parse(localStorage.getItem('storage_usage')).contentData;
    }

    static store(key, data) {
        if (localStorage.getItem(key)) {
            localStorage.removeItem(key);
        }

        localStorage.setItem(key, JSON.stringify(data), );
    }

    static clear() {
        const keys = [
            'about_us_page',
            'cookie_policy',
            'data_protection',
            'extensions_detailed',
            'gallery_images',
            'general_terms_and_conditions',
            'home_page',
            'high_lights',
            'packages',
            'packages_detailed',
            'slider_images',
            'questions'
        ];

        keys.forEach(key => {
            if (localStorage.getItem(key)) {
                localStorage.removeItem(key);
            }
        });
    }

    static isGoogleAnalyticsEnabled() {
        if (!localStorage.getItem('storage_usage')) {
            return true;
        }
        return JSON.parse(localStorage.getItem('storage_usage')).analytics;
    }
}