export default class LinkTranslations {

  static HU = {
    CSOMAGOK : 'Csomagok',
    CSOMAGOK_LINK : '/csomagok',
    INGYENES_CSOMAG: 'FREE Csomag',
    INGYENES_CSOMAG_LINK: '/csomagok/free',
    BASIC_CSOMAG: 'BASIC Csomag',
    BASIC_CSOMAG_LINK: '/csomagok/basic',
    PRO_CSOMAG: 'PRO Csomag',
    PRO_CSOMAG_LINK: '/csomagok/pro',
    BUSINESS_CSOMAG: 'BUSINESS Csomag',
    BUSINESS_CSOMAG_LINK: '/csomagok/business',
    BUSINESS_PLUS_CSOMAG: 'BUSINESS PLUS Csomag',
    BUSINESS_PLUS_CSOMAG_LINK: '/csomagok/business-plus',

    MODULOK: 'Modulok',
    MODULOK_LINK : '/modulok',

    KEPTAR: 'Képtár',
    KEPTAR_LINK : '/keptar',

    GYIK: 'GYIK',
    GYIK_LINK : '/gyik',

    ROLUNK: 'Rólunk',
    ROLUNK_LINK: '/rolunk',

    FELHASZNALOI_KEZIKONYV: 'Felhasználói kézikönyv',
    FELHASZNALOI_KEZIKONYV_LINK: 'https://wiki.ecorrect.hu/hu/home',

    FACEBOOK: 'facebook.ecorrect.hu',
    FACEBOOK_LINK: 'https://www.facebook.com/ecorrecthu',

    ADATVEDELEM: 'Adatvédelmi szabályzat',
    ADATVEDELEM_LINK: '/adatvedelmi-szabalyzat',
    SUTIPOLITIKA: 'Sütipolitika',
    SUTIPOLITIKA_LINK: '/sutipolitika',
    ASZF: 'Általános szerződési feltételek',
    ASZF_LINK : '/altalanos-szerzodesi-feltetelek'

  }

  static RS = {
    CSOMAGOK : 'Paketi',
    CSOMAGOK_LINK : '/paketi',
    INGYENES_CSOMAG: 'STARTER Paket',
    INGYENES_CSOMAG_LINK: '/paketi/starter',
    BASIC_CSOMAG: 'BASIC Paket',
    BASIC_CSOMAG_LINK: '/paketi/basic',
    PRO_CSOMAG: 'PRO Paket',
    PRO_CSOMAG_LINK: '/paketi/pro',
    BUSINESS_CSOMAG: 'BUSINESS Paket',
    BUSINESS_CSOMAG_LINK: '/paketi/business',
    BUSINESS_PLUS_CSOMAG: 'BUSINESS PLUS Paket',
    BUSINESS_PLUS_CSOMAG_LINK: '/paketi/business-plus',    

    MODULOK: 'Moduli',
    MODULOK_LINK : '/moduli',

    KEPTAR: 'Slike o aplikaciji',
    KEPTAR_LINK : '/slike-o-aplikaciji',

    GYIK: 'Često pitaju',
    GYIK_LINK : '/cesto-pitaju',

    ROLUNK: 'O nama',
    ROLUNK_LINK: '/o-nama',
    
    FELHASZNALOI_KEZIKONYV: 'Korisničko uputstvo',
    FELHASZNALOI_KEZIKONYV_LINK: 'https://wiki.ecorrect.rs/sr/home',

    FACEBOOK: 'facebook.ecorrect.rs',
    FACEBOOK_LINK: 'https://www.facebook.com/ecorrectrs',

    ADATVEDELEM: 'Zaštita podataka',
    ADATVEDELEM_LINK: '/zastita-podataka',
    SUTIPOLITIKA: 'Kolačići',
    SUTIPOLITIKA_LINK: '/kolacici',
    ASZF: 'Opšti uslovi korišćenja',
    ASZF_LINK : '/opsti-uslovi-koriscenja'
  }

}