import axios from "axios";
import { useEffect, useState } from "react";
import {
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselItem,
  Col,
  Row,
} from "reactstrap";

import { Environment } from "../../../../core/env/environment";
import { LocalStorageService } from "../../../../core/storage-service/LocalStorageService";

import "./HomePageImageSlider.css";

const HomePageImageSlider = (args) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const ENTITY = "slider-images?populate=*";
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if(!isLoading){
      return;
    }
    fetchData();
  });

  function fetchData() {
    const stored = LocalStorageService.find("slider_images");
    if (stored && stored.length) {
      setImages(stored);
      setIsLoading(false);
      return;
    }
    axios
      .get(
        Environment.getEntityTypeApiEndpoint(ENTITY),
        Environment.getApiRequestHeaders()
      )
      .then(({ data }) => {
        data.data = data.data.sort((a, b) =>
          a.attributes.weight < b.attributes.weight ? -1 : 1
        );
        let imagesLoaded = [];
        data.data.forEach((imageData) => {
          imagesLoaded.push(createImage(imageData));
        });
        setImages(imagesLoaded);
        LocalStorageService.store("slider_images", imagesLoaded);
        setIsLoading(false);
      })
      .catch((error) => {
        // IGNORED
      });
  }

  function createImage(imageData) {
    return {
      key: imageData.id,
      title: imageData.attributes.title,
      subTitle: imageData.attributes.subTitle,
      src:
        Environment.getPublicEndpoint() +
        imageData.attributes.image.data.attributes.formats.medium.url.substring(
          -1
        ),
      srcLarge:
        Environment.getPublicEndpoint() +
        imageData.attributes.image.data.attributes.formats.large.url.substring(
          -1
        ),
    };
  }

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  /*const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };*/

  const largeSize = args.ismobile ? false : window.innerWidth > 2559 ? true :false;
  const slides = images.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.key}
      >
        <div className="d-flex justify-content-center">
          <img
            className="img-fluid"
            src={largeSize ? item.srcLarge : item.src}
            alt={item.title + ", " + item.subTitle}
          />
        </div>
        <CarouselCaption
          captionHeader={item.title}
          captionText={item.subTitle}
          className="image-carousel-caption rounded mt-3 p-1 text-secondary"
        ></CarouselCaption>
      </CarouselItem>
    );
  });

  return isLoading ? (
    <></>
  ) : (
    <Row className="mt-5 pt-5 align-middle">
      <Col lg="12" md="12" sm="12" xs="12">
        <Carousel
          className="main-image-carousel"
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          {...args}
        >
          {slides}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>
      </Col>
    </Row>
  );
};

export default HomePageImageSlider;

/*


          <CarouselIndicators
            items={images}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
            className="bg-secondary text-light rounded"
          />

*/
