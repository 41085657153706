import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { marked } from "marked";
import { useEffect, useState } from "react";
import { NavLink, NavLink as RRNavLink } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

import { Environment } from "../../../../core/env/environment";
import { LocalStorageService } from "../../../../core/storage-service/LocalStorageService";
import BasicMetaData from "../../../layout/BasicMetaData";
import LoginOrRegister from "../../../login-or-register/LoginOrRegister";
import GenericNotFound from "../../../misc-components/GenericNotFound";
import InterestedIn from "../../../misc-components/InterestedIn";
import Service from "../service/Service";
import { PackageService } from "./PackageService";

import "./SelectedPackageView.css";

const SelectedPackageView = (props) => {
  const ENTITY = "packages?populate=*";
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [packages, setPackages] = useState([]);
  const [packageTitles, setPackageTitles] = useState([]);
  const [activePackage, setActivePackage] = useState(null);
  const [yearlySubscription, setYearlySubscrition] = useState(false);

  useEffect(() => {
    if (activePackage && activePackage.id === props.activePackage.id) {
      changeLoadingState();
      return;
    }
    if (
      activePackage?.id !== props.activePackage.id ||
      !packages[props.activePackage.id]
    ) {
      loadData();
      return;
    }

    function addPackageTitle(title) {
      let titles = packageTitles;
      titles.push(title);
      setPackageTitles(titles);
    }

    function changeActivePage(packagex) {
      setActivePackage(packagex);
    }

    function changePackages(packagesx) {
      setPackages(packagesx);
    }

    function changeLoadingState() {
      setIsLoading(false);
    }

    function loadData() {
      axios
        .get(
          Environment.getEntityTypeApiEndpoint(ENTITY),
          Environment.getApiRequestHeaders()
        )
        .then(({ data }) => {
          data.data = data.data.sort((a, b) =>
            a.attributes.weight < b.attributes.weight ? -1 : 1
          );
          data.data.forEach((d) => {
            addPackageTitle(d.attributes.title);
          });
          changePackages(data.data);
          changeActivePage(data.data[props.activePackage.id - 1]);
          changeLoadingState();
          LocalStorageService.store("packages_list", data.data);
        })
        .catch((error) => setError(error));
    }
  }, [props.activePackage.id, packages, activePackage, packageTitles]);

  if (
    !props.activePackage.id ||
    !packages ||
    isLoading ||
    (!isLoading && !activePackage) ||
    error
  ) {
    return GenericNotFound({
      error: error,
      content: !activePackage,
      invalidPath: false,
      translator: props.translator
    });
  }

  function findAddedText() {
    if (props.activePackage.id > 1) {
      return (
        <p>{ props.translator.t('A_NAGYOBB_CSOMAG_MAGABA_FOGLALJA_A_KISEBB_CSOMAG_SZOLGALTATASAIT') }
          <br></br>
          { props.translator.t('OPCIONALIS_HAVI_VAGY_EVES_ELOFIZETES',[activePackage.attributes.title]) }
        </p>
      );
    } else {
      return <></>;
    }
  }

  function createService(item, subIndex) {
    const isFirstService = subIndex < 1;
    return (
      <Col
        lg="2"
        md="3"
        sm="12"
        xs="12"
        className="selected-package-view-col hoverable-item mb-2 selected-package-view-service-item"
        key={"col-" + item.attributes.title + subIndex}
      >
        <Service
          key={item.attributes.title + subIndex}
          service={item}
          boldTitle={true}
          firstService={isFirstService}
          color={PackageService.findCardBgClassName(
            packages[props.activePackage.id - 1].attributes.title
          )}
          isPayedPackage={props.activePackage.id !== 1}
          payedIconSize={3}
          ismobile={props.ismobile}
          translator={props.translator}
        ></Service>
      </Col>
    );
  }

  const bgClassName = PackageService.findCardBgClassName(
    activePackage.attributes.title
  );

  function findLinks() {
    return activePackage?.id ? (
      <div className="column d-flex column mt-2">
        {findPreviousLinkComponent(props.activePackage.id)}
        <FontAwesomeIcon
          className={"p-1 package-text-color-" + bgClassName}
          icon={solid("ellipsis")}
          size="3x"
        />
        {findNextLinkComponent(props.activePackage.id)}
      </div>
    ) : (
      <></>
    );
  }

  function findNextPackageLinkBackGroundColor(activePackageId) {
    return !packages[activePackageId]
      ? ""
      : " package-text-color-" +
          PackageService.findCardBgClassName(
            packages[activePackageId].attributes.title
          );
  }

  function findPreviousPackageLinkBackGroundColor(activePackageId) {
    let color = PackageService.findCardBgClassName(
      packages[activePackageId - 2].attributes.title
    );
    if (color === "free") {
      return " text-secondary";
    }
    return !packages[activePackageId - 1]
      ? ""
      : " package-text-color-" +
          PackageService.findCardBgClassName(
            packages[activePackageId - 2].attributes.title
          );
  }

  function findPreviousLinkComponent(activePackageId) {
    return !packages[activePackageId - 1] || !packages[activePackageId - 2] ? (
      <div>
        <FontAwesomeIcon className="p-1" icon={solid("ellipsis")} size="3x" />
      </div>
    ) : (
      <div>
        <NavLink
          activeclassname="active"
          to={findPreviousPackageLink(props.activePackage.id)}
          tag={RRNavLink}
        >
          <FontAwesomeIcon
            className={
              "p-1" +
              findPreviousPackageLinkBackGroundColor(props.activePackage.id)
            }
            icon={solid("backward")}
            size="3x"
          />
        </NavLink>
      </div>
    );
  }

  function findPreviousPackageLink(activePackageId) {
    if (
      !activePackageId ||
      !packages[activePackageId - 1] ||
      !packages[activePackageId - 2]
    ) {
      return props.translator.findLinkTranslation('CSOMAGOK_LINK');
    }

    return (
      props.translator.findLinkTranslation('CSOMAGOK_LINK') + "/" +
      (Environment.LANGUAGE_CODE === 'RS' && packages[activePackageId - 2].attributes.title === 'FREE' ? 'starter' : PackageService.findCardBgClassName(
        packages[activePackageId - 2].attributes.title
      ))
    );
  }

  function findNextLinkComponent(activePackageId) {
    return packages[activePackageId] ? (
      <div>
        <NavLink
          activeclassname="active"
          to={findNextPackageLink(activePackageId)}
          tag={RRNavLink}
        >
          <FontAwesomeIcon
            className={
              "p-1" + findNextPackageLinkBackGroundColor(props.activePackage.id)
            }
            icon={solid("forward")}
            size="3x"
          />
        </NavLink>
      </div>
    ) : (
      <div>
        <FontAwesomeIcon className="p-1" icon={solid("ellipsis")} size="3x" />
      </div>
    );
  }

  function findNextPackageLink(activePackageId) {
    if (!activePackageId || !packages[activePackageId]) {
      return props.translator.findLinkTranslation('CSOMAGOK_LINK');
    }
    return (
      props.translator.findLinkTranslation('CSOMAGOK_LINK') + "/" +
      PackageService.findCardBgClassName(
        packages[activePackageId].attributes.title
      )
    );
  }

  function findStarterLink(bgClassName){
    if(bgClassName !== 'free'){
      return bgClassName;
    }

    return Environment.LANGUAGE_CODE === 'RS' && bgClassName === 'free' ? 'starter' : bgClassName;
  }

  return (
    <Row className="my-3">
      <BasicMetaData
        title={props.translator.t('CSOMAGOK_ES_ARAK') + ' - ' + props.translator.t(activePackage.attributes.title.replace(" ", "_"))}
        content={activePackage.attributes.description}
        link={(Environment.LANGUAGE_CODE === 'HU' ? "csomagok/" : "paketi/") + findStarterLink(bgClassName)}
      />
      <Row className="row rounded pb-1 selected-package-view-body-wrapper justify-content-center">
        <Col lg="6" md="6" sm="12" xs="12">
          <h1 className={"package-text-color-" + bgClassName}>
            {props.translator.t(activePackage.attributes.title.replace(" ", "_"))} -{" "}
            <small className="text-secondary">
              {activePackage.attributes.description}
            </small>
          </h1>
          <div
            className="main-content-wrapper"
            dangerouslySetInnerHTML={{
              __html: marked.parse(activePackage.attributes.body),
            }}
          ></div>
          {findAddedText()}
        </Col>
        <Col
          lg="3"
          md="6"
          sm="12"
          xs="12"
          className="d-flex flex-column justify-content-around align-items-center selected-package-view-items-wrapper"
        >
          {props.ismobile ? (
            <></>
          ) : (
            <FontAwesomeIcon
              className={"package-text-color-" + bgClassName}
              icon={solid("cube")}
              size="10x"
            />
          )}
          <div className="d-flex justify-content-center">{findLinks()}</div>
          <div className="d-flex">
            <div className="m-2">
              <Button
                className={
                  !yearlySubscription
                    ? "main-content-bg-color"
                    : "bg-light color-ec-3"
                }
                onClick={() => setYearlySubscrition(false)}
              >
                { props.translator.t('HAVI_ELOFIZETES') }{" "}
              </Button>
            </div>
            <div className="m-2">
              <Button
                className={
                  yearlySubscription
                    ? "main-content-bg-color"
                    : "bg-light color-ec-3"
                }
                onClick={() => setYearlySubscrition(true)}
              >
                { props.translator.t('EVES_ELOFIZETES') }{" "}
              </Button>
            </div>
          </div>
          <div className="mt-1 text-center">
                    {PackageService.createPriceContainer(
                      1,
                      activePackage,
                      yearlySubscription,
                      PackageService.findCardBgClassName(activePackage.attributes.title)
                    )}
                    </div>
        </Col>
      </Row>
      <h3 className={"text-center mt-5 package-text-color-" + bgClassName}>
      { props.translator.t('A_CSOMAG_SZOLGALTATASAI') }
      </h3>
      <Row className="mt-5 selected-package-view-items-wrapper justify-content-center">
        {(() => {
          let subContainer = [];
          if (!activePackage.attributes.services) {
            return subContainer;
          }
          activePackage.attributes.services.data.forEach((item, subIndex) => {
            subContainer.push(createService(item, subIndex, activePackage));
          });
          return subContainer;
        })()}
      </Row>
      <Row className="justify-content-around">
        <Col lg="4" md="4" sm="12" xs="12">
          <LoginOrRegister translator={props.translator}></LoginOrRegister>
        </Col>
        <Col lg="4" md="4" sm="12" xs="12">
          <InterestedIn translator={props.translator}></InterestedIn>
        </Col>
      </Row>
    </Row>
  );
};

export default SelectedPackageView;
