export class Environment {
    static SITE_NAME = "eCorrect";
    static HOST = "http://localhost:3000/";
    static LANGUAGE_CODE = "RS";

    // HU

    /* static API_ADDRESS = "strapi.ecorrect.hu";
    static DOMAIN_NAME = "ecorrect.hu";
    static REGISZTRACIO = "https://szamlazo.ecorrect.hu/regisztracio";
    static BEJELENTKEZES = "https://szamlazo.ecorrect.hu/bejelentkezes";
    static FELHASZNALOI_KEZIKONYV = "https://wiki.ecorrect.hu/hu/home";
    static LIVE_API_TOKEN ="dee776cfbdd4524c04cff8b2518545c089347e38d8c4c44b3c9cf2c1a1336856adc1c5b74c5af80f9a274d50c04339ee4cd807d8b9485a1f47f738a53ad33b1264d57f72820392615fe7b7cff35e443770e6034e43083161dff77e469454b5e17a676040f8cd21d6b8ab38ae77040a14e516112c65a06d1ba8381342907572e1";
    static GOOGLE_SITE_KEY = '6LcrtCQlAAAAAJYCdWWrdiBh8GPp_8HfSWmMBCjM';
    static MEASURMENT_ID = 'G-TTRNDSPFZC'; */

    // RS
    static API_ADDRESS = "strapi.ecorrect.rs";
    static DOMAIN_NAME = "ecorrect.rs";
    static REGISZTRACIO = "https://fakture.ecorrect.rs/registracija";
    static BEJELENTKEZES = "https://fakture.ecorrect.rs/prijava";
    static FELHASZNALOI_KEZIKONYV = "https://wiki.ecorrect.rs/sr/home";
    static LIVE_API_TOKEN ="c8d8ddb93c8d6c7206d4e47dcd035eee64d9d0a24a292cef5fafc3bfce4fa2a2db88a3ad1614b6961d5edf691d9ebeed22c3519afbe572c6110deb2455cf17046577701616eaa4b52b6e9d31637f2a1bc4ae37e0b4bce55fc7ac36dc867d7073443ab18338633f0b32ace12a5d3ea669ccd946796cb2ea812cc500473936607e";
    static GOOGLE_SITE_KEY = '6LecmOciAAAAACHCb8F2-hoD9DElpALTFFlaOJdK';
    static MEASURMENT_ID = 'G-2XMNMJ5ESJ';

    static getEntityTypeApiEndpoint(entityType) {
        return Environment.getApiEndpoint() + entityType;
    }

    static getApiEndpoint() {
        return "https://" + this.API_ADDRESS + "/api/";
    }

    static getPublicEndpoint() {
        return "https://" + this.API_ADDRESS + "/";
    }

    static getApiRequestHeaders() {
        return {
            headers: {
                Authorization: `Bearer ${Environment.LIVE_API_TOKEN}`,
            },
        };
    }

    static findLogoUrl(){
        return "/images/logo_" + this.LANGUAGE_CODE.toLowerCase() + ".png";
    }
}