import { Environment } from "../../core/env/environment";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link, NavLink as RRNavLink, useLocation } from "react-router-dom";
import { Button, Col, Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarText, NavbarToggler, NavItem, NavLink, Row, UncontrolledDropdown } from "reactstrap";
import './NavigationBar.css';
import { useEffect } from "react";

const NavigationBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <Row>
      <Row className="text-center pt-2 pb-2 bg-primary text-light">
        <Col>
          <span className="me-2">Izvorni kod softvera za prodaju.</span>
          <Link to="/izvorni-kod-softvera-za-prodaju" className="text-light">Vise informacije</Link>
        </Col>
      </Row>
      <Navbar color="dark" expand="lg" dark container="fluid" className={isOpen ? 'navbar-is-open' : ''}>
        <Link className="navbar-brand" to="/" tag={RRNavLink}>
          <div>
            <img
              alt="logo"
              src={Environment.findLogoUrl()}
              style={{
                height: 50,
                width: "auto",
                overflowY: "hidden",
                marginLeft: "1rem",
              }}
            />{" "}
            {Environment.SITE_NAME}
          </div>
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className={'me-auto nav-link-wrapper' + (isOpen ? '-is-open' : '')} navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                {props.translator.findLinkTranslation('CSOMAGOK')}
              </DropdownToggle>
              <DropdownMenu end className="bg-dark text-light">
                <DropdownItem>
                  <NavItem>
                    <NavLink
                      activeclassname="active"
                      to={props.translator.findLinkTranslation('CSOMAGOK_LINK')}
                      tag={RRNavLink}
                    >
                    {props.translator.t('CSOMAGOK_ES_ARAK')}
                    </NavLink>
                  </NavItem>
                </DropdownItem>
                <DropdownItem>
                  <NavItem>
                    <NavLink
                      activeclassname="active"
                      to={props.translator.findLinkTranslation('INGYENES_CSOMAG_LINK')}
                      tag={RRNavLink}
                    >
                      {props.translator.findLinkTranslation('INGYENES_CSOMAG')}
                    </NavLink>
                  </NavItem>
                </DropdownItem>
                <DropdownItem>
                  {" "}
                  <NavLink
                      activeclassname="active"
                      to={props.translator.findLinkTranslation('BASIC_CSOMAG_LINK')}
                      tag={RRNavLink}
                    >
                      {props.translator.findLinkTranslation('BASIC_CSOMAG')}
                    </NavLink>
                </DropdownItem>
                <DropdownItem>
                  {" "}
                  <NavItem>
                  <NavLink
                      activeclassname="active"
                      to={props.translator.findLinkTranslation('PRO_CSOMAG_LINK')}
                      tag={RRNavLink}
                    >
                      {props.translator.findLinkTranslation('PRO_CSOMAG')}
                    </NavLink>
                  </NavItem>
                </DropdownItem>
                <DropdownItem>
                  {" "}
                  <NavItem>
                  <NavLink
                      activeclassname="active"
                      to={props.translator.findLinkTranslation('BUSINESS_CSOMAG_LINK')}
                      tag={RRNavLink}
                    >
                      {props.translator.findLinkTranslation('BUSINESS_CSOMAG')}
                    </NavLink>
                  </NavItem>
                </DropdownItem>
                <DropdownItem>
                  {" "}
                  <NavItem>
                  <NavLink
                      activeclassname="active"
                      to={props.translator.findLinkTranslation('BUSINESS_PLUS_CSOMAG_LINK')}
                      tag={RRNavLink}
                    >
                      {props.translator.findLinkTranslation('BUSINESS_PLUS_CSOMAG')}
                    </NavLink>
                  </NavItem>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
            <NavLink
                activeclassname="active"
                to={props.translator.findLinkTranslation('MODULOK_LINK')}
                tag={RRNavLink}
              >
                {props.translator.findLinkTranslation('MODULOK')}
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink
                activeclassname="active"
                to={props.translator.findLinkTranslation('KEPTAR_LINK')}
                tag={RRNavLink}>
                {props.translator.findLinkTranslation('KEPTAR')}
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink
                activeclassname="active"
                to={props.translator.findLinkTranslation('GYIK_LINK')}
                tag={RRNavLink}>
                {props.translator.findLinkTranslation('GYIK')}
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink
                activeclassname="active"
                to={props.translator.findLinkTranslation('ROLUNK_LINK')}
                tag={RRNavLink}>
                {props.translator.findLinkTranslation('ROLUNK')}
              </NavLink>
            </NavItem>
          </Nav>
          <NavbarText className="d-flex column justify-content-between">
              <div className="p-1">
              <Button
                className="p-1 main-content-bg-color"
                onClick={(event) =>
                  (window.location.href =
                    Environment.REGISZTRACIO)
                }
              >
                {props.translator.t('REGISZTRACIO_ES_DEMO')}{" "}
                <FontAwesomeIcon icon={solid("right-to-bracket")} />
              </Button>
              </div>
              <div  className="p-1">
              <Button
                className="p-1"
                color="success"
                onClick={(event) =>
                  (window.location.href = Environment.BEJELENTKEZES)
                }
              >
                {props.translator.t('BEJELENTKEZES')}{" "} <FontAwesomeIcon icon={solid("right-to-bracket")} />
              </Button>
              </div>
          </NavbarText>
        </Collapse>
      </Navbar>
    </Row>
  );
};

export default NavigationBar;
