import formatPrice from "../../../../core/formatter";

const PriceContainer = (props) => {
  return (
    <p className={'price-container ' + props.color}>
        {props.yearlySubscription ? formatPrice(props.priceYearly, props.currency) : formatPrice(props.priceMonthly, props.currency)}
        <span>{props.yearlySubscription ? props.translator.t('EVES_ELOFIZETES_SHORT') : props.translator.t('HAVI_ELOFIZETES_SHORT')}</span>
    </p>
  )
};

export default PriceContainer;
